export default {
  state: {
    token: localStorage.getItem("token") || '',
    userInfo: JSON.parse(localStorage.getItem("userInfo")) || {},
    store: JSON.parse(localStorage.getItem("store")) || {},
  },
  getters: {
    // userInfo: state => state.userInfo,
    // store: state => state.store,
  },
  mutations: {

    // clearToken(state) {
    //   state.userToken = {}
    //   localStorage.removeItem('userToken')
    // },
    // getToken(state) {
    //   state.userToken = state.userToken || localStorage.getItem('userToken')
    // }
    SET_TOKEN(state, data) {
      state.token = data
      localStorage.setItem('token', data)
    },
    CLEAR_USER(state) {
      state.token = ''
      state.userInfo = {}
      state.store = {}
      localStorage.removeItem('token')
      localStorage.removeItem('userInfo')
      localStorage.removeItem('store')
    },
    SET_USER_INFO(state, data) {
      state.userInfo = data
      localStorage.setItem("userInfo", JSON.stringify(data))
    },
    SET_STORE(state, data) {
      state.store = data
      localStorage.setItem("store", JSON.stringify(data))
    },

  }
}