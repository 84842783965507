import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

import BvToast from '@/utils/bvToast'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    }
  },
  routes: [{
    path: '/',
    redirect: {
      name: 'login'
    }
  },
  // {
  //   path: '/home',
  //   name: 'home',
  //   component: () => import('@/views/home/Home.vue'),
  //   meta: {
  //     pageTitle: '首页看板',
  //     breadcrumb: [{
  //       text: '首页看板',
  //       active: true,
  //     }, ],
  //   },
  // },

  // ==========商家服务===========
  //跑腿管理
  {
    path: '/merchantService/errandManagement',
    name: 'errandManagement',
    component: () => import('@/views/merchantService/errandManagement/ErrandManagement'),
    meta: {
      pageTitle: '跑腿管理',
      breadcrumb: [{
        text: '商家服务',
      },
      {
        text: '跑腿管理',
        active: true,
      },
      ],
    },
  },
  //打印机
  {
    path: '/merchantService/printer',
    name: 'printer',
    component: () => import('@/views/merchantService/printer/Printer'),
    meta: {
      pageTitle: '打印机',
      breadcrumb: [{
        text: '商家服务',
      },
      {
        text: '打印机',
        active: true,
      },
      ],
    },
  },
  //支付配置
  {
    path: '/merchantService/paySetting',
    name: 'paySetting',
    component: () => import('@/views/merchantService/paySetting/PaySetting'),
    meta: {
      pageTitle: '支付配置',
      breadcrumb: [{
        text: '商家服务',
      },
      {
        text: '支付配置',
        active: true,
      },
      ],
    },
  },
  //支付申请
  {
    path: '/merchantService/payApplication',
    name: 'payApplication',
    component: () => import('@/views/merchantService/payApplication/PayApplication'),
    meta: {
      pageTitle: '支付申请',
      breadcrumb: [{
        text: '商家服务',
      },
      {
        text: '支付申请',
        active: true,
      },
      ],
    },
  },

  //门店设置
  {
    path: '/merchantService/storeSetting',
    name: 'storeSetting',
    component: () => import('@/views/merchantService/storeSetting/StoreSetting'),
    meta: {
      pageTitle: '门店设置',
      breadcrumb: [{
        text: '商家服务',
      },
      {
        text: '门店设置',
        active: true,
      },
      ],
    },
  },
  // ==========商户跟进===========
  //商户查询
  {
    path: '/merchantFollow/merchantInquire',
    name: 'merchantInquire',
    component: () => import('@/views/merchantFollow/merchantInquire/MerchantInquire'),
    meta: {
      pageTitle: '商户查询',
      breadcrumb: [{
        text: '商户跟进',
      },
      {
        text: '商户查询',
        active: true,
      },
      ],
    },
  },
  //续费通知-短信监控
  {
    path: '/merchantFollow/renewalNotice/SMSmonitoring',
    name: 'SMSmonitoring',
    component: () => import('@/views/merchantFollow/renewalNotice/SMSmonitoring/SMSmonitoring'),
    meta: {
      pageTitle: '短信监控',
      breadcrumb: [{
        text: '商户跟进',
      },
      {
        text: '续费通知',
      },
      {
        text: '短信监控',
        active: true,
      },
      ],
    },
  },
  //续费通知-即将逾期
  {
    path: '/merchantFollow/renewalNotice/immediatelyOverdue',
    name: 'immediatelyOverdue',
    component: () => import('@/views/merchantFollow/renewalNotice/immediatelyOverdue/ImmediatelyOverdue'),
    meta: {
      pageTitle: '即将逾期',
      breadcrumb: [{
        text: '商户跟进',
      },
      {
        text: '续费通知',
      },
      {
        text: '即将逾期',
        active: true,
      },
      ],
    },
  },
  // ==========联盟管理===========
  //联盟客
  {
    path: '/allianceManagement/allianceUser',
    name: 'allianceUser',
    component: () => import('@/views/allianceManagement/allianceUser/AllianceUser'),
    meta: {
      pageTitle: '联盟管理',
      breadcrumb: [{
        text: '联盟管理',
      },
      {
        text: '联盟客',
        active: true,
      },
      ],
    },
  },
  //统计报表
  {
    path: '/allianceManagement/statisticalReport',
    name: 'statisticalReport',
    component: () => import('@/views/allianceManagement/statisticalReport/StatisticalReport'),
    meta: {
      pageTitle: '联盟管理',
      breadcrumb: [{
        text: '联盟管理',
      },
      {
        text: '统计报表',
        active: true,
      },
      ],
    },
  },
  //佣金发放
  {
    path: '/allianceManagement/commissionDistribution',
    name: 'commissionDistribution',
    component: () => import('@/views/allianceManagement/commissionDistribution/CommissionDistribution'),
    meta: {
      pageTitle: '联盟管理',
      breadcrumb: [{
        text: '联盟管理',
      },
      {
        text: '佣金发放',
        active: true,
      },
      ],
    },
  },


  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/LoginOut',
    name: 'LoginOut',
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
  ],
})

router.beforeEach((to, form, next) => {
  let token = localStorage.getItem('token') || ''
  if (!token && to.name !== 'login') {
    next({
      name: 'login'
    })
  } else if (token && to.name === 'login') {
    next({
      name: 'errandManagement'
    })
  } else {
    // if (to.name === 'generalFormat' && to.query.commodTitle) {
    //   to.meta.breadcrumb[1].text = `${to.query.commodTitle}规格设置`
    //   to.meta.pageTitle = to.query.commodTitle
    // } else if (to.name === 'generalFormat' && !to.query.commodTitle) {
    //   to.meta.breadcrumb[1].text = '通用规格'
    //   to.meta.pageTitle = '通用规格'
    // }
    next()
  }

  if (to.name === 'LoginOut') {
    store.commit('CLEAR_USER')
    next({
      name: 'login'
    })
    BvToast.toast('退出成功', {
      title: `提示`,
      variant: "primary",
      solid: true,
    })
  }
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router