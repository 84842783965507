
export default {
  namespaced: true,
  state: {
    isShowAllExcelBtn: false,
    isShowAddGroupBtn: false,
    globalBigId: '',
    totalDataArr: [],
    totalDataArrTitle: ''
  },
  getters: {},
  mutations: {},
  actions: {},
}